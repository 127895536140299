<template lang="pug">
  .rate-plan-settings
    AppNestedRoutesTabs(
      :routes="routes"
    )
</template>

<script>
  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    computed: {
      routes() {
        return [
          {
            name: "RatePlan",
            title: this.$t("header.title.rate_plan")
          },
          {
            name: "PriceTable",
            title: this.$t("header.title.price_table")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
